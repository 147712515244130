<template>
  <div class="pa-6">
    <p><strong>Status:</strong> {{ status }}</p>
    <p v-if="accountStage === 'lapsed' || accountStage === 'expired'">
      <strong>Expired on:</strong> {{ expDate }}
    </p>
    <p v-else-if="subscriptionPrice">
      <strong>Renews on:</strong> {{ expDate }}
    </p>
    <p v-else-if="accountStage === 'active'">
      <strong>Expires on:</strong> {{ expDate }}
    </p>
    <p v-if="subscriptionPrice">
      <strong>Price:</strong> ${{ subscriptionPrice }} per year
    </p>
    <component
      :is="`access-${accountStage}`"
      @close="close"
      :price="subscriptionPrice"
      :plan="subscriptionPlan"
    />
    <div class="d-flex align-center mt-6">
      <v-btn
        text
        depressed
        @click="close"
        >{{ closeLabel }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

const AccessActive = () => import("./subscription/AccessActive")
const AccessLapsed = () => import("./subscription/AccessLapsed")

export default {
  name: "UserMenuSubscription",
  props: {
    closeLabel: {
      default: "close",
    },
  },
  components: {
    AccessLapsed,
    AccessActive,
  },
  data: () => ({
    stages: {
      lapsed: "Lapsed",
      expired: "Expired",
      active: "Active",
      unactivated: "Unactivated",
    },
  }),
  computed: {
    ...mapGetters("account", ["user_access"]),
    ...mapGetters("app", ["pricing", "app_settings"]),
    expDate() {
      return `${this.user_access.value.expiryDate.day}/${this.user_access.value.expiryDate.month}/${this.user_access.value.expiryDate.year}`
    },
    accountStage() {
      if (this.user_access.isActive) {
        return "active"
      }

      if (this.user_access.isGracePeriod) {
        return "lapsed"
      }

      return null
    },
    subscriptionPlan() {
      return (
        this.user_access.value.subscription ||
        this.app_settings.pricing.defaultSubscription
      )
    },
    subscriptionPrice() {
      return this.subscriptionPlan
        ? this.app_settings.pricing.subscription[this.subscriptionPlan]
        : null
    },
    status() {
      if (this.user_access.isRenewing && this.user_access.isActive) {
        return "Subscription Active"
      }

      return "No subscription"
    },
  },
  methods: {
    close() {
      this.$emit("close")
    },
  },
}
</script>
